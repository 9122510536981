import { Seo, Text } from 'components';
import Header from 'components/Header';
import React from 'react';
import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';

const Terms = () => (
  <>
    <Seo />
    <Header hasDivider={true} />
    <Container>
      <Wrapper>
        <StyledTitle>Terms and conditions</StyledTitle>
        <StyledHeading>1. Welcome to no.Diet!</StyledHeading>
        <StyledText>
          The following are the terms and conditions (hereinafter referred to as
          the &quot;Agreement&quot;) which govern your access and use of our
          online platform (hereinafter referred to as the &quot;Platform&quot;).
          This Website is owned and operated by UAB &quot;Medical Score&quot;,
          company code 305445861, with our registered office at J. Žemgulio g.
          46, LT-35239 Panevėžys, the Republic of Lithuania. Its affiliates,
          partners, representatives, employees, and contractors (hereinafter
          referred to as no.Diet, “We”, “Our”, or “Us”) provide you (hereinafter
          referred to as “You”, “Your”, “User”, or “Users”) with Web access or
          mobile application access to the Platform.
        </StyledText>
        <StyledText>
          By accessing or using the Platform, You are entering into this
          Agreement. You should read this Agreement carefully before starting to
          use the Platform. If You do not agree to be bound to any of the terms
          of this Agreement, You must not access or use the Platform.
        </StyledText>
        <StyledHeading>2. Privacy and security</StyledHeading>
        <StyledText>
          Protecting and safeguarding any information You provide through the
          Platform is important to Us. Information about Our security and
          privacy practices can be found on Our Privacy Policy (hereinafter
          referred to as the Privacy Policy).
          <br />
          By agreeing to this agreement and/or by using the Platform, You are
          also agreeing to the terms of the Privacy Policy. The Privacy Policy
          is incorporated into and deemed a part of this agreement. The same
          rules that apply regarding changes and revisions of this agreement
          also apply to changes and revisions of the Privacy Policy.
        </StyledText>
        <StyledHeading>3. Subscription pricing and terms</StyledHeading>
        <StyledText>
          All prices and costs in the United States of America are in US Dollars
          unless otherwise indicated.
        </StyledText>
        <StyledText>
          Pricing in other countries may vary and actual charges may be
          converted to your local currency depending on the country of
          residence.
        </StyledText>
        <StyledText>
          Unless User cancels the subscription, Company will automatically renew
          the applicable service when it comes up for renewal and will take
          payment from the payment method associated with the User’s account.
        </StyledText>
        <StyledText>Subscription can be canceled in these ways:</StyledText>
        <StyledText>
          - If User has purchased the subscription on our website, User will not
          be able to control it through the Apple App Store or Google Play.
          Instead, User may easily cancel the subscription by contacting the
          support team at help@no.diet
        </StyledText>
        <StyledText>
          - If User has purchased the subscription through the Apple App Store
          or Google Play, User might cancel the subscription only through his
          Apple or Google Account.
        </StyledText>
        <StyledText>
          User understands that deleting the app or your personal data does not
          cancel the subscriptions and it does not imply any right to refunds.
        </StyledText>
        <StyledText>
          If the User does not wish for service to automatically renew, he may
          elect to cancel the subscription at least 48 hours before the end of
          current period, in which case, the Services will be terminated upon
          expiration of the then current term, unless he manually renews the
          Services prior to that date.
        </StyledText>
        <StyledHeading>4. Third party content</StyledHeading>
        <StyledText>
          The Platform may contain other content, products, or services which
          are offered or provided by third parties (hereinafter referred to as
          &quot;Third Party Content&quot;), including but not limited to links
          to other Websites and advertisements which are related to Third Party
          Content. We have no responsibility for the creation of any such Third
          Party Content including but not limited to any related products,
          practices, terms, or policies, and We will not be liable for any
          damage or loss caused by any Third Party Content.
        </StyledText>
        <StyledHeading>
          5. Disclaimer of Warranty and Limitation of Liability
        </StyledHeading>
        <StyledText>
          You hereby release Us and agree to hold Us harmless from any and all
          causes of action and claims of any nature resulting from the Services
          or the Platform, including without limitation any act, omission,
          opinion, response, advice, suggestion, information, and/or any other
          content or information accessible through the platform.
        </StyledText>
        <StyledText>
          You understand, agree, and acknowledge that the Platform is provided
          &quot;as is&quot; without any express or implied warranties of any
          kind, including but not limited to merchantability, non-infringement,
          security, and fitness for a particular purpose or accuracy. The use of
          the platform is at Your own risk. To the fullest extent of the law, We
          expressly disclaim all warranties of any kind, whether expressed or
          implied.
        </StyledText>
        <StyledText>
          You understand, agree, and acknowledge that We shall not be liable to
          You or to any third party for any indirect, incidental, consequential,
          special, punitive, or exemplary damages.
        </StyledText>
        <StyledText>
          You understand, agree, and acknowledge that Our aggregate liability
          for damages arising with respect to this Agreement and any and all use
          of the Platform will not exceed the total amount of money paid by You
          or on Your behalf through the platform in the 12 months period prior
          to the date of the claim.
        </StyledText>
        <StyledText>
          If the applicable law does not allow the limitation of liability as
          set forth above, the limitation will be deemed modified solely to the
          extent necessary to comply with the applicable law. This limitation of
          liability shall survive the termination or expiration of this
          Agreement.
        </StyledText>
        <StyledHeading>
          6. Your account, representations, conduct, and commitments
        </StyledHeading>
        <StyledText>
          You hereby confirm that You are legally able to consent to receive
          application services, or have the consent of a parent or guardian, and
          that You are legally able to enter into a contract.
        </StyledText>
        <StyledText>
          You hereby confirm and agree that all the information that You
          provided in or through the Platform, and the information that You will
          provide in or through the Platform in the future, is accurate, true,
          current, and complete. Furthermore, You agree that during the term of
          this Agreement You will make sure to maintain and update this
          information so it will continue to be accurate, current, and complete.
        </StyledText>
        <StyledText>
          You agree, confirm, and acknowledge that You are responsible for
          maintaining the confidentiality of Your password and any other
          security information related to Your account (hereinafter referred to
          as &quot;Account Access&quot;). We advise You to change Your password
          frequently and to take extra care in safeguarding Your password. You
          agree to notify Us immediately of any unauthorized use of Your Account
          Access or any other concern for breach of Your account security. You
          agree, confirm, and acknowledge that We will not be liable for any
          loss or damage incurred as a result of someone else using Your
          account, either with or without Your consent and/or knowledge.
        </StyledText>
        <StyledText>
          You agree, confirm, and acknowledge that You are solely and fully
          liable and responsible for all activities performed using Your Account
          Access. You further acknowledge and agree that We will hold You liable
          and responsible for any damage or loss incurred as a result of the use
          of Your Account Access by any person, whether authorized by You or
          not, and You agree to indemnify Us for any such damage or loss.
        </StyledText>
        <StyledText>
          You agree and commit not to use an account or Account Access of any
          other person for any reason.
        </StyledText>
        <StyledText>
          You agree and confirm that Your use of the Platform, including but not
          limited to the Digital content and the app Services, are for Your own
          personal use only and that You are not using the Platform for or
          behalf of any other person or organization.
        </StyledText>
        <StyledText>
          You agree and commit not to interfere with or disrupt, or attempt to
          interfere with or disrupt, any of Our systems, services, servers,
          networks, or infrastructure, or any of the Platform&apos;s systems,
          services, servers, networks or infrastructure, including without
          limitation obtaining unauthorized access to the aforementioned.
        </StyledText>
        <StyledText>
          You agree and commit not to make any use of the Platform for the
          posting, sending, or delivering of unsolicited email and/or
          advertisement or promotion of goods and services; malicious software
          or code; unlawful, harassing, privacy invading, abusive, threatening,
          vulgar, obscene, racist, or potentially harmful content; any content
          that infringes a third party right including intellectual property
          rights; any content that may cause damage to a third party; and any
          content which may constitute, cause, or encourage a criminal action,
          or violate any applicable law.
        </StyledText>
        <StyledText>
          You agree and commit not to violate any applicable local, state,
          national or international law, statute, ordinance, rule, regulation,
          or ethical code in relation to Your use of the Platform and Your
          relationship with Us.
        </StyledText>
        <StyledText>
          If You receive any file from Us, whether through the Platform or not,
          You agree to check and scan this file for any virus or malicious
          software prior to opening or using this file.
        </StyledText>
        <StyledText>
          You will indemnify Us, defend Us, and hold Us harmless from and
          against any and all claims, losses, causes of action, demands,
          liabilities, costs, or expenses including but not limited to
          litigation and reasonable attorneys&apos; fees and expenses arising
          out of or relating to any of the following: Your access to or use of
          the Platform; any actions made with Your account or Account Access
          whether by You or by someone else; Your violation of any of the
          provisions of this Agreement; non-payment for any of the services,
          including Services for the Platform, which were provided through the
          Platform; and Your violation of any third party right, including
          without limitation any intellectual property right, publicity,
          confidentiality, and property or privacy right. This clause shall
          survive expiration or termination of this Agreement.
        </StyledText>
        <StyledText>
          You confirm and agree to use only credit cards or other payment means
          (hereinafter referred to as “Payment Means”) which You are duly and
          fully authorized to use, and that all payment-related information that
          You provided and will provide in the future, to or through the
          Platform, is accurate, current, and correct and will continue to be
          accurate, current, and correct.
        </StyledText>
        <StyledText>
          You agree to pay all fees and charges associated with Your account on
          a timely basis and according to the fees schedule and the terms and
          rates as published in the Platform. By providing Us with Your Payment
          Means You authorize Us to bill and charge You through that Payment
          Means and You agree to maintain valid Payment Means information in
          Your account information.
        </StyledText>
        <StyledText>
          If You have any concerns about a bill or a payment, please contact Us
          immediately by sending an email to help@no.diet. We will evaluate Your
          issue on a case-by-case basis and, at Our discretion, take steps to
          resolve any issue including but not limited to extending Your
          subscription at no cost to You, and issuing partial or full refunds
          when applicable.
        </StyledText>
        <StyledText>
          We follow a no refund policy, unless the product is proven to be
          faulty. In such cases, User must contact our customer support at
          help@no.diet within 14 days upon purchase and must provide detailed
          information proving Company&apos;s product fault (with visual proof
          attached).
        </StyledText>
        <StyledText>
          If the User fails to meet the deadlines of our refund policy, we will
          be unable to offer a refund.
        </StyledText>
        <StyledText>
          If the User has purchased the Services through the Apple App Store or
          Google Play for any questions and/or requests regarding the refunds
          and the payment Users should contact the Apple App Store or Google
          Play Store users support.
        </StyledText>
        <StyledText>
          Under the applicable legal acts, the User has a right to change their
          mind and return delivered (received) goods within 14 (fourteen) days
          from the day of delivery. However, there is also an exception
          established by those legal acts, according to which, the right to
          return goods is not applicable for contracts regarding the supply of
          Digital content in case the provision of such Digital content has
          already commenced. Therefore, in case the Digital content is already
          provided to the User, the User loses his/her right of withdrawal of
          the Agreement.
        </StyledText>
        <StyledText>
          In case the agreement consists of Digital content, then once the
          payment for such digital content is made, the User agrees and provides
          consent to begin the performance of this contract. User confirms that
          once he receives the Digital content it will be considered that We
          have fulfilled its duties in accordance with these terms and has fully
          provided all digital content which was acquired by the User.
        </StyledText>
        <StyledHeading>
          7. Modifications, Termination, Interruption of, and Disruptions to the
          Platform
        </StyledHeading>
        <StyledText>
          You understand, agree, and acknowledge that We may modify, suspend,
          disrupt, or discontinue the Platform, any part of or use of the
          Platform, whether to all clients or to You specifically, at any time
          with or without notice to You. You agree and acknowledge that We will
          not be liable for any of the aforementioned actions or for any losses
          or damages that are caused by any of the aforementioned actions.
        </StyledText>
        <StyledText>
          The Platform depends on various factors such as software, hardware,
          and tools, either Our own or those owned and/or operated by Our
          contractors and suppliers. While We make commercially reasonable
          efforts to ensure the Platform&apos;s reliability and accessibility,
          You understand and agree that no platform can be 100% reliable and
          accessible and so We cannot guarantee that access to the Platform will
          be uninterrupted or that it will be accessible, consistent, timely, or
          error-free at all times.
        </StyledText>
        <StyledHeading>8. Notices</StyledHeading>
        <StyledText>
          We may provide notices or other communications to You regarding the
          Agreement or any aspect of the Platform by email to the email address
          that We have on record or by posting it online. The date of receipt
          shall be deemed the date on which such notice is given. Notices sent
          to Us must be delivered by email to help@no.diet.
        </StyledText>
        <StyledHeading>9. Other Notes Regarding the Agreement</StyledHeading>
        <StyledText>
          The Agreement and Our relationship with You shall both be interpreted
          solely in accordance with the laws of the State of Delaware excluding
          any rules governing choice of laws.
        </StyledText>
        <StyledText>
          You irrevocably agree that the exclusive venue for any action or
          proceeding arising out of relating to the Agreement or Our
          relationship with You, regardless of theory, shall be courts located
          in the State of California. You irrevocably consent to the personal
          jurisdiction of the aforementioned courts and hereby waive any
          objection to the exercise of jurisdiction by the aforementioned
          courts. Nothing in the Agreement, including the choice of the laws of
          the State of Delaware, affects Your statutory rights as a consumer to
          rely on the mandatory consumer protection provisions contained in the
          law of the country in which You live. The Agreement constitutes the
          entire Agreement between You and Us. You confirm that You have not
          relied upon any promises or representations by Us except as set forth
          in this Agreement. We may freely transfer or assign this Agreement or
          any of its obligations hereunder.
        </StyledText>
        <StyledText>
          We may change this Agreement by posting modifications on the Platform.
          Unless otherwise specified by Us, all modifications shall be effective
          upon posting. Therefore, You are encouraged to check the terms of this
          Agreement frequently. The last update date of this Agreement is posted
          at the bottom of the Agreement. By using the Platform after the
          changes become effective, You agree to be bound by such changes to the
          Agreement. If You do not agree to the changes, You must terminate
          access to the Platform and participation in its services.
        </StyledText>
        <StyledText>
          If any provision of this Agreement is held by a court of competent
          jurisdiction to be illegal, invalid, unenforceable, or otherwise
          contrary to law, the remaining provisions of this Agreement will
          remain in full force and effect. To clear any doubt, all clauses
          regarding limitations of liabilities and indemnification shall survive
          the termination or expiration of this Agreement
        </StyledText>
        <StyledText>Last Updated: January 1st, 2025</StyledText>
      </Wrapper>
    </Container>
  </>
);

export default Terms;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 84rem;

  @media ${mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledTitle = styled(Text)`
  color: #141515;
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 2.5rem;
  @media ${tablet} {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
`;

const Container = styled.div`
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 2.5rem 1rem 3rem;
  }
`;

const StyledText = styled(Text)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1.5rem;
`;

const StyledHeading = styled(Text)`
  color: #141515;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.0135rem;
  padding-bottom: 1.5rem;
`;
