import React, { FC, useState } from 'react';
import { PrimaryButton } from 'components';
import Text from 'components/Text';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import videoFile from 'assets/videos/chart.mp4';

interface SummaryTypes {
  time: string[];
  buttonTitle: string;
  onContinue: () => void;
}

const ResultsPersonalized: FC<SummaryTypes> = ({
  buttonTitle,
  onContinue,
  time,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    onContinue();
  };

  return (
    <Container>
      <VideoStyled autoPlay playsInline muted>
        <source src={videoFile} type="video/mp4" />
      </VideoStyled>
      <TimeContainer>
        {time?.map((item, index) => (
          <Time key={index}>{item}</Time>
        ))}
      </TimeContainer>

      <ButtonContainer>
        <ContinueButtonStyled
          onClick={handleClick}
          disabled={loading}
          loading={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsPersonalized;

const TimeContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 78%;
  margin-left: 7px;
`;

const Time = styled(Text)`
  color: #777;
  text-align: center;

  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const VideoStyled = styled.video`
  max-width: 28.75rem;
  width: 100%;
  margin-bottom: 6.5px;
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;
