import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import { klaviyoLink } from 'utils/klavyio';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import { PasswordInput } from 'components/inputs/PasswordInput';

interface FormData {
  email: string;
  emailConfirm: string;
  password?: string;
  repeatedPassword?: string;
}

const defaultValues: FormData = {
  email: '',
  emailConfirm: '',
  password: undefined,
  repeatedPassword: undefined,
};

const validateSchema = z
  .object({
    email: z
      .string({ required_error: 'Please enter your email' })
      .email('Please enter a valid email address'),
    emailConfirm: z.string(),
    password: z
      .string({ required_error: 'Please enter your password' })
      .min(6, 'Password must be no shorter than 6 symbols'),
    repeatedPassword: z.string({
      required_error: 'Please confirm your password',
    }),
  })

  .refine(data => data.password === data.repeatedPassword, {
    message: 'Confirm password do not match with your password',
    path: ['repeatedPassword'],
  });

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  max-width: 30.75rem;
  width: 100%;
  padding: 2rem 1rem;
  margin: 0 auto;
  min-height: calc(100vh - 72px);
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const Title = styled(Text)`
  color: #141515;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #141515;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 2rem;
  width: 100%;
  @media ${tablet} {
    font-size: 0.875rem;
    padding: 0.5rem 0 1.5rem;
  }
`;

const Form = styled.form`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    padding-bottom: 3rem;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const FormContentContainer = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);
`;

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useQuery();

  const [errors, setErrors] =
    useState<Partial<Record<keyof FormData, string>>>();
  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);

  const { quiz_answers, user, code, selected_plan, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
  });

  const { goToUpgrade } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-nd';
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  useEffect(() => {
    if (user) {
      formValues.email = user.email;
      formValues.emailConfirm = user.email;
      setFormValues(formValues);
    }
  }, [user]);

  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  const resolveAvoidIngredients = () => {
    const values = [
      quiz_answers?.exclude_products_protein_diary,
      quiz_answers?.exclude_products_fruits_vegetables,
      quiz_answers?.exclude_products_nuts_grains_breads,
    ];

    if (values.every(value => value === 'all')) {
      return 'all';
    }

    return values.filter(value => value !== 'all').join('|');
  };

  const avoidIngredients = resolveAvoidIngredients();

  const activityLevels = {
    almost_every_day: 4,
    several_times_per_week: 3,
    several_times_per_month: 2,
    never: 1,
  };

  const userRTDBProperties = {
    weightHistory: {
      [getTodayDateString()]: quiz_answers?.current_weight?.kg,
    },
    userDetails: {
      isQuizComplete: true,
      code: code,
      startingWeight: quiz_answers?.current_weight?.kg,
      goalWeight: quiz_answers?.target_weight?.kg,
      gender: quiz_answers?.gender,
      height: quiz_answers?.height?.cm,
      activityLevel: activityLevels[quiz_answers?.exercise],
      age: quiz_answers?.age?.year,
      unitsOfMeasure: 'imperial',
      avoidIngredients: avoidIngredients,
    },
  };

  const handleRegister = async (data: FormData) => {
    setLoading(true);

    // await klaviyoLink(data.email);

    Tracking.trackCTAButton(location.pathname);

    const userCredentials = {
      email: data.email,
      password: data.password,
    };
    const funnelData = {
      code: code ?? '',
      quizAnswers: quiz_answers ?? {},
      selectedPlan: selected_plan ?? {},
      quiz,
      onboardingBooked: false,
    };

    try {
      await axios.post(config.FIREBASE_REGISTER_URL, {
        userCredentials,
        userRTDBProperties,
      });

      Tracking.trackRegistration(funnelData.code);
      // if user came from support email - prevent going to upgrade sequence
      if (isFromSupportCase) {
        setIsRegisterComplete(true);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);

      setLoading(false);
    }

    const selectedPlanKey = selected_plan?.key || null;
    const upgradeKey = selectedPlanKey
      ? Object.keys(upgradeSequences[selectedPlanKey])[0]
      : null;
    if (upgradeKey) {
      goToUpgrade(upgradeKey);
    } else {
      goToUpgrade('upgrade');
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const validateInputs = async (data: FormData) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse(data);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const validateInput = async (data: Partial<FormData>, field: string) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse({
        ...formValues,
        [field]: data[field as keyof FormData],
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          if (e.path[0] !== field) return;
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    const isEmpty = Object.keys(validationErrors).length === 0;
    if (isEmpty) {
      return setErrors({ ...errors, [field]: null });
    }
    return setErrors({ ...errors, ...validationErrors });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = await validateInputs(formValues);
    if (Object.keys(validationErrors).length === 0) {
      handleRegister(formValues);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    await validateInput(
      { [event.target.name]: event.target.value },
      event.target.name,
    );
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header hasDivider={true} />
      <PageContainer>
        {isRegisterComplete ? (
          <RegisterCompleted />
        ) : (
          <ContentContainer>
            <Title>{data?.title}</Title>
            <Subtitle>{data?.subtitle}</Subtitle>

            <Form onSubmit={handleSubmit}>
              <FormContentContainer>
                <InputWrapper>
                  <TextInput
                    name="email"
                    defaultValue={user?.email ?? ''}
                    label={data?.emailLabel}
                    error={errors?.email}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Please enter your email"
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    name="emailConfirm"
                    defaultValue={user?.email ?? ''}
                    label={data?.confirmEmailLabel}
                    error={errors?.emailConfirm}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Please enter your email"
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <PasswordInput
                    type="password"
                    name="password"
                    label={data?.passwordLabel}
                    error={errors?.password}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <PasswordInput
                    type="password"
                    name="repeatedPassword"
                    label={data?.confirmPasswordLabel}
                    error={errors?.repeatedPassword}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
              </FormContentContainer>
              <Wrapper>
                <StyledButton
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  {data?.submitBtnTitle}
                </StyledButton>
              </Wrapper>
            </Form>
          </ContentContainer>
        )}
      </PageContainer>
    </>
  );
};

const InputWrapper = styled.div`
  margin-top: 0.6rem;
`;

export default Register;

const Wrapper = styled.div`
  width: 100%;
  max-width: 28.75rem;
  margin: 0 auto;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;
