import React, { ComponentProps, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';
import { tablet } from 'styles/breakpoints';

const Button = styled(PrimaryButton)<{ isClicked: boolean }>`
  max-width: 28.75rem;
  width: 100%;
  transition: transform 0.1s ease-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ isClicked }) => (isClicked ? 'scale(0.98)' : 'scale(1)')};

  margin: 0 auto;
`;

const Wrapper = styled.div<{
  removeBorder?: boolean;
  isNotSticky?: boolean;
}>`
  width: 100%;
  transition: transform 0.1s ease-in-out;
  @media ${tablet} {
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    ${({ isNotSticky }) =>
      !isNotSticky &&
      `
        position: fixed; 
      padding: 1rem 1rem;
      &::before {
      content: '';
      position: absolute;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 80%;
      background: #FAF9F7;
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #FAF9F7;
      z-index: -1;
    }`}
  }
`;

const ContinueButton = (props: ComponentProps<typeof PrimaryButton>) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 150);
  };
  return (
    <Wrapper
      onClick={handleClick}
      removeBorder={props?.removeBorder}
      isNotSticky={props?.isNotSticky}
    >
      <Button isClicked={isClicked} {...props}>
        {props?.title ?? 'Continue'}
      </Button>
    </Wrapper>
  );
};

export default ContinueButton;
