import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { Text } from 'components';
import {
  mMobile,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile_landing.png';
import socProof_des from 'assets/images/landing/desktop_landing.png';
import Logo from 'assets/icons/logo.svg';
// import Triangle from "assets/icons/triangle.svg";
// import WhitePercent from "assets/icons/white-percent.svg";
import { usePageView, useScrollToTop } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { useLocation } from '@reach/router';
import { normalizeStates } from 'utils/localization';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
  hasBanner?: boolean;
}

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const [animateIndex, setAnimateIndex] = useState<number | null>(null);
  const { isTablet } = useQuery();
  const location = useLocation();

  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;

  const geo = useSelector((state: AppState) => state.user?.geolocation);

  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;

  usePageView({
    city: encodeURIComponent(
      geo?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geo?.iso_country?.toLowerCase(),
    state: normalizeStates(geo?.iso_country || '', geo?.iso_state || ''),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.href, location.pathname]);

  const pickSocialProfImage = (variant?: string) =>
    // if (variant === "blackFriday") {
    //   return {
    //     mob: socProofBF,
    //     des: socProof_desBF,
    //   };
    // }
    // if (variant === 'xmas') {
    //   return {
    //     mob: xmasSocProof,
    //     des: xmasSocProof_des,
    //   };
    // }
    // if (variant === 'ny') {
    //   return {
    //     mob: nySocProof,
    //     des: nySocProof_des,
    //   };
    // }
    ({
      mob: socProof,
      des: socProof_des,
    });
  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() => handleClick(option, index)}
          isSingleButton={question?.options?.length === 1}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          animate={animateIndex === index}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );
  const renderGenderButtonsWithBanner = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButtonWithBanner
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={true}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButtonWithBanner>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderGenderOptionsWithBanner = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainerWithBanner
        hasBanner={!!question?.bannerTexts}
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtonsWithBanner(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainerWithBanner>
    );
  };

  const handleMouseDown = (index: number) => {
    setAnimateIndex(index); // Set the index of the button being pressed
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      setAnimateIndex(null); // Reset animation after the delay
    }, 100);
  };

  const handleClick = (option: any, index: number) => {
    handleMouseDown(index);
    setTimeout(() => {
      handleOptionsSelect({
        [question?.key]: option?.value,
        label: option?.label,
      });
      handleMouseUp();
    }, 300);
  };

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <>
      <Container>
        {!isAddsFlow && (
          <HeaderWrapper>
            <Header isMobileApp={false} />
          </HeaderWrapper>
        )}

        <Wrapper isFullHeight={isAddsFlow}>
          {isAddsFlow && (
            <>
              {!!question?.bannerTexts && isTablet ? (
                <HeaderWrapper>
                  <HeaderContainer>
                    <HeaderImg src="assets/icons/logo.svg" alt="" />
                  </HeaderContainer>
                </HeaderWrapper>
              ) : (
                <HeaderContainer>
                  <HeaderImg src="assets/icons/logo.svg" alt="" />
                </HeaderContainer>
              )}
            </>
          )}
          <W1>
            <SocialProof
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
              isFullHeight={isAddsFlow}
              isBanner={!!question?.bannerTexts}
              isOrganic={!isAddsFlow}
            />
          </W1>

          <WrappedItem>
            <W3>
              <TitleContainer>
                <Title
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>

              <ButtonsTitleContainer>
                <ButtonsLabel
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                />
              </ButtonsTitleContainer>
              {question?.bannerButtonText ? (
                <ButtonsMainConatiner>
                  {renderGenderOptionsWithBanner()}
                  {question?.bannerButtonText && (
                    <ButtonsBanner>
                      {/* <WhitePercent />
                      <LeftTriangle />
                      <RightTriangle /> */}
                      <ButtonBannerText
                        dangerouslySetInnerHTML={{
                          __html: question?.bannerButtonText,
                        }}
                      />
                    </ButtonsBanner>
                  )}
                </ButtonsMainConatiner>
              ) : (
                renderGenderOptions()
              )}
            </W3>
          </WrappedItem>
        </Wrapper>

        {!isAddsFlow ? (
          <LCFooter
            whiteFooter
            disclaimerText={question?.disclaimerParagraphs || []}
          />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const ButtonBannerText = styled.p`
  color: #fff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;
  b {
    font-weight: 700;
  }
  span {
    font-weight: 700;
    color: #f7c800;
  }
  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.024rem;
  }
`;

const ButtonsBanner = styled.p`
  position: relative;
  display: flex;
  max-width: 38.8125rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: linear-gradient(0deg, #000 0%, #000 100%),
    linear-gradient(0deg, #000 0%, #000 100%),
    linear-gradient(0deg, #000 0%, #000 100%),
    linear-gradient(0deg, #000 0%, #000 100%), #000;
  color: #fef3f3;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-top: 0.875rem;
  gap: 0.5rem;
  height: 3.5rem;
  @media ${tablet} {
    max-width: unset;
    height: 3rem;
  }
`;

// const LeftTriangle = styled(Triangle)`
//   position: absolute;
//   top: -0.375rem;
//   left: 20%;
// `;

// const RightTriangle = styled(Triangle)`
//   position: absolute;
//   top: -0.375rem;
//   right: 20%;
// `;

const ButtonsMainConatiner = styled.div`
  max-width: 38.8125rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    max-width: unset;
  }
`;

const GenderButtonWithBanner = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;

  @media ${tablet} {
    height: 3rem;
  }
`;

const HeaderWrapper = styled.section`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-wrap: wrap;
`;

const Circle = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
`;

const CircleScience = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.8rem;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 0.975rem */
  letter-spacing: -0.018rem;
  color: #2a4b42;
  @media ${sxMobile} {
    font-size: 0.5rem;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 9.375rem;
  width: 100%;
  margin: 0 0 1rem;
`;

const IconContainer = styled.div`
  display: flex;
  max-width: 38.8rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  @media ${mobile} {
    margin-top: unset;
  }
`;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  @media ${tablet} {
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #faf9f7;
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: 72px;
  @media ${tablet} {
    padding: 1rem 1rem 0;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.main<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  max-width: 90rem;
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
  margin: 0 auto;
  background: #faf9f7;
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    padding: 0 0 3rem;
    flex-direction: column;
    align-items: unset;
    gap: 0.5rem;
  }
`;

const HeaderImg = styled(Logo)`
  height: 2.44rem;
  @media ${tablet} {
    height: 2rem;
  }
`;

const HeaderContainer = styled.div<{ top?: string }>`
  position: absolute;
  top: ${({ top = '0' }) => top};
  left: 3rem;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  margin: 0 auto;
  width: 100%;
  background-color: #faf9f7;

  @media ${tablet} {
    position: static;
    top: auto;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0 1.06rem;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 10000%;
  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000% 0;

  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const SocialProof = styled.div<{
  des: string;
  mob: string;
  isFullHeight?: boolean;
  isBanner?: boolean;
  isOrganic?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});
    height: 308px;
    ${({ isOrganic }) => isOrganic && 'margin-top: 0.81rem;'}
  }
  @media ${mMobile} {
    height: 180px;
  }
`;
const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  @media ${tablet} {
    align-items: center;
    padding-bottom: 24px;
    justify-content: center;
  }
`;

const ButtonsContainerWithBanner = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 1rem;
  @media ${tablet} {
    ${({ hasBanner }) => !hasBanner && 'padding-bottom: 32px;'}
    justify-content: center;
  }
`;

const Title = styled.h1`
  color: #141515;
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  max-width: 35rem;
  @media ${tablet} {
    font-size: 2rem;
    text-align: center;
  }
`;

const ButtonsLabel = styled(Text)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    text-align: center;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  justify-content: flex-start;
  display: flex;
  width: 100%;

  @media ${tablet} {
    justify-content: center;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ color?: boolean; animate: boolean }>`
  padding: 0 1rem;
  max-width: 10.3125rem;
  width: 100%;
  height: 3rem;
  ${({ color }) => color && `background:${color};`}
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ animate }) => (animate ? 'scale(0.98)' : 'scale(1)')};
  @media ${tablet} {
  }
`;

const OtherGenderButton = styled(GenderButton)`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  background-color: transparent;

  border: none;
  div p {
    text-align: center;
    color: #000;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 0.75rem;
`;

const StyledHeaderText = styled(Text)`
  color: #436460;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;
