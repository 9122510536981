import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { PrimaryButton, Text } from 'components';
import Checkmark from 'assets/icons/green-checkmark.svg';
import Mark from 'assets/icons/extra-red-mark.svg';
import Logo from 'assets/icons/logo.svg';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';

interface FatBurningTypes {
  buttonTitle: string;
  nowTitle: string;
  topTitle: string;
  levelTexts: string[];
  you: string;
  nowList: string[];
  futureTitle: string;
  futureList: string[];
  onContinue: () => void;
}
const FatBurning: FC<FatBurningTypes> = ({
  buttonTitle,
  nowTitle,
  topTitle,
  levelTexts,
  you,
  nowList,
  futureTitle,
  futureList,
  onContinue,
}) => {
  const [loading, setLoading] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const [leftPosition, setLeftPosition] = useState('-5%');

  const handleClick = () => {
    setLoading(true);
    onContinue();
  };

  useEffect(() => {
    setRenderCount(prev => prev + 1);

    setAnimate(false);
    setTimeout(() => {
      setLeftPosition('5%');
      setAnimate(true);
    }, 10);
  }, []);
  return (
    <Container>
      <Content>
        <TopTitleContainer>
          <SectionTitle>{topTitle}</SectionTitle>
        </TopTitleContainer>
        <ChartContainer2>
          <BubbleContainer
            animate={animate}
            key={renderCount}
            leftPosition={leftPosition}
          >
            <YouText>
              {you}
              <Arrow />
            </YouText>
            <Bubble />
          </BubbleContainer>

          <Line2 />
          <Levels>
            {levelTexts.map((item, index) => (
              <LevelText key={index}>{item}</LevelText>
            ))}
          </Levels>
        </ChartContainer2>
      </Content>

      <ChartContainer>
        <LeftSide>
          <LeftTitle>
            <Icon>
              <Mark />
            </Icon>
            {nowTitle}
          </LeftTitle>
          <ListBox>
            {nowList.map((item: string, index: number) => (
              <>
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
                {index < nowList.length - 1 && <Line />}
              </>
            ))}
          </ListBox>
        </LeftSide>

        <RightSide>
          <RightTitle>
            <Icon>
              <Checkmark />
            </Icon>
            {futureTitle}
            <StyledLogo />
          </RightTitle>
          <ListBox>
            {futureList.map((item: string, index: number) => (
              <>
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
                {index < futureList.length - 1 && <Line />}
              </>
            ))}
          </ListBox>
        </RightSide>
      </ChartContainer>
      <ButtonContainer>
        <ContinueButtonStyled
          onClick={handleClick}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default FatBurning;

const StyledLogo = styled(Logo)`
  width: 4.01738rem;
  height: 0.8125rem;
  @media ${tablet} {
    width: 3.70831rem;
    height: 0.75rem;
  }
`;

const Icon = styled.section`
  width: 1.5rem;
  height: 1.5rem;
`;

const SectionTitle = styled.p`
  color: #141515;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 28.75rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin: 0 auto 1.5rem;
  border-radius: 0.75rem;
  background: #fff;

  box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);
  position: relative;
`;

const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: linear-gradient(
        1deg,
        rgba(250, 249, 247, 0.9) 1%,
        rgba(250, 249, 247, 0.9) 98.64%
      );
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: rgb(250, 249, 247);
      z-index: -1;
    }
  }
`;
const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ChartContainer = styled.section`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const ChartContainer2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.13rem;
  @media ${tablet} {
    margin-top: 2.62rem;
  }
`;

const Line2 = styled.div`
  width: 100%;
  height: 0.52006rem;
  border-radius: 625rem;
  background: linear-gradient(
    90deg,
    #fc826e 0%,
    #fbbf77 39.43%,
    #f3ed80 70.52%,
    #bff4a4 100%
  );
`;

const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin: 0 1rem;
`;

const LevelText = styled(Text)`
  color: #727373;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const BubbleContainer = styled.div<{ animate: boolean; leftPosition: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  bottom: 66%;
  left: ${({ leftPosition }) => leftPosition};
  transition: left 0.5s ease-out;
  @media ${tablet} {
    top: -113%;
    bottom: unset;
  }
`;

const YouText = styled.div`
  display: inline-flex;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #f04d41;
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.05rem */
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: #f04d41;
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  border: 1px solid #f04d41;
  border-right: 0;
  background: #fde5e2;
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border: 1px solid #21b66b;
  border-left: none;
  background: #d4f7e0;
`;

const LeftTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: #f04d41;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const RightTitle = styled(LeftTitle)`
  color: #21b66b;
  text-wrap: nowrap;
`;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

const ListItem = styled(Text)`
  color: #141515;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    span {
      display: block;
    }
  }
`;

const Bubble = styled.div`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 5.878px solid #f04d41;
  border-radius: 3rem;
`;

const Line = styled.section`
  height: 1px;
  width: 100%;
  background: #1415151a;
`;
