import React, {
  FC,
  FocusEvent,
  InputHTMLAttributes,
  Ref,
  useState,
} from 'react';
import styled from 'styled-components';

interface TextInputProps {
  label?: string;
  error?: string;
  inputRef?: Ref<HTMLInputElement>;
}

interface InputProps {
  focused: boolean;
  hasError: boolean;
  required?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  padding-top: 0.5rem;
`;

const Label = styled.label`
  color: #141515;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  span {
    color: #f04d41;
  }
`;

const Required = styled.label`
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red100};
`;

const Error = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.14331rem;
  color: rgb(255, 109, 92);
  padding-top: 0.5rem;
`;

const Input = styled.input<InputProps>`
  outline: none;
  width: 100%;
  color: #141515;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 3rem;
  padding: 0.875rem 0.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #ededed;
  background: #faf9f7;
  ::placeholder {
    color: #727373;
  }
`;

export const TextInput: FC<
  TextInputProps & InputHTMLAttributes<HTMLInputElement>
> = ({
  type,
  placeholder = 'Enter here',
  inputRef,
  disabled,
  label,
  error,
  required,
  onFocus,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const toggleFocus = () => {
    setFocused(!focused);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();

    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();

    onBlur?.(e);
  };

  return (
    <Container>
      {!!label && (
        <Label>
          {label} {required && <span>*</span>}
        </Label>
      )}
      {!!error && <Error>{error}</Error>}
      <InputContainer>
        <Input
          ref={inputRef}
          type={type}
          disabled={disabled}
          focused={focused}
          hasError={!!error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
          placeholder={placeholder}
        />
      </InputContainer>
    </Container>
  );
};
